<template>
  <div class="sku-setting-box">
    <sku-batch-setting ref="skuBatchRef" :need-edit="edit" :isPlatform='isPlatform' :get-default-obj="getDefaultObj" :type-rate-format="typeRateFormat" :init-data="initData" :is-collect="isCollect" :syncFlag='syncFlag' :get-price-by-tax="getPriceByTax" :getMathRound="getMathRound" :countryUnifyFlag='countryUnifyFlag' />
    <sku-normal-setting v-if="!formDisabled" ref="skuNormalRef" :isPlatform='isPlatform' :need-edit="edit" :get-default-obj="getDefaultObj" :type-rate-format="typeRateFormat" :init-data="initData" :is-collect="isCollect" :form-disabled="formDisabled" :get-price-by-tax="getPriceByTax" :getMathRound="getMathRound" :countryUnifyFlag='countryUnifyFlag' />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import SkuBatchSetting from './SkuBatchSetting'
import SkuNormalSetting from './SkuNormalSetting'
import NP from 'number-precision'

let needEdit = [
  {
    variable: 'skuPic',
    name: '商品图',
    type: 'image',
  },
  {
    variable: 'weight',
    name: '重量',
    unit: '(kg)',
    type: 'decimal',
  },
  {
    variable: 'stock',
    name: '库存',
    type: 'integer',
  },
  {
    variable: 'supplyPrice',
    name: '成本价',
    tip:
      '商家购入商品的价格，商品的购进价值。如果不想透露成本价，填写的金额可以和供货价一样，加价率为0%',
    unit: '(元)',
    type: 'decimal',
  },
  {
    variable: 'tradePriceMarkup',
    name: '加价率',
    unit: '(%)',
    tip:
      '1、供货价减去成本价后的金额与成本价的比率。2、在成本价的基础上，根据加价率自动算出供货价。3、如果上级成本价有变动，会根据加价率，供货价自动进行相应的变动。',
    type: 'rate',
    collectEdit: true,
  },
  {
    variable: 'tradePrice',
    name: '供货价',
    unit: '(元)',
    tip: '分销商拿货的价格。',
    type: 'decimal',
    collectEdit: true,
    width: 0,
  },
  {
    variable: 'priceMarkup',
    name: '零售价加价率',
    unit: '(%)',
    type: 'rate',
    collectEdit: true,
  },
  {
    variable: 'price',
    name: '零售价',
    unit: '(元)',
    type: 'decimal',
    tip: '1、零售价格，小程序显示的商品价格。2、分销商采集时，作为建议零售价格。',
    collectEdit: true,
  },
  {
    variable: 'originalPriceMarkup',
    name: '划线价加价率',
    unit: '(%)',
    type: 'rate',
    collectEdit: true,
  },
  {
    variable: 'originalPrice',
    name: '划线价',
    unit: '(元)',
    type: 'decimal',
    tip: '小程序的划线价格，商品的划线价。',
    collectEdit: true,
  },
  {
    variable: 'points',
    name: '折标价',
    unit: '(元)',
    type: 'decimal',
    tip: '佣金 = 折标价 * 佣金比例',
    collectEdit: true,
  },
]
let needEditNoSupplyPrice = needEdit.filter((x) => x.variable !== 'supplyPrice')
export default {
  name: 'SkuSetting',

  components: {
    SkuBatchSetting,
    SkuNormalSetting,
  },

  props: {
    initData: Object,
    isCollect: Boolean,
    syncFlag: Boolean,
    countryUnifyFlag: Boolean,
    isPlatform: Boolean,
    productType: String,
  },

  setup (props) {
    const skuBatchRef = ref(null)
    const skuNormalRef = ref(null)
    const edit = ref(null)
    const formDisabled = computed(() =>
      Boolean(skuBatchRef.value && skuBatchRef.value.form.skuList.length)
    )
    watch(
      () => props.isCollect,
      (newVal) => {
        if (newVal) {
          edit.value = needEdit
        } else {
          edit.value = needEditNoSupplyPrice
        }
      },
      { immediate: true }
    )
    watch(
      () => props.productType,
      (newVal) => {
        if (newVal === '5') {
          if (!needEdit.find((x) => x.variable === 'skuNo')) {
            needEdit.unshift({
              variable: 'skuNo',
              name: '编码',
              type: 'string',
            })
          }
          if (!needEditNoSupplyPrice.find((x) => x.variable === 'skuNo')) {
            needEditNoSupplyPrice.unshift({
              variable: 'skuNo',
              name: '编码',
              type: 'string',
            })
          }
        } else {
          if (needEdit.find((x) => x.variable === 'skuNo')) {
            let index = needEdit.findIndex((x) => x.variable === 'skuNo')
            needEdit.splice(index, 1)
          }
          if (needEditNoSupplyPrice.find((x) => x.variable === 'skuNo')) {
            let index = needEditNoSupplyPrice.findIndex((x) => x.variable === 'skuNo')
            needEditNoSupplyPrice.splice(index, 1)
          }
        }
        if (props.isCollect) {
          edit.value = needEdit
        } else {
          edit.value = needEditNoSupplyPrice
        }
      },
      { immediate: true }
    )
    // 获取默认表单
    function getDefaultObj (hasError) {
      let result = {}
      needEdit.forEach((x) => {
        result[x.variable] = x.type === 'rate' ? '10' : ''
        if (hasError) result[`${x.variable}Error`] = ''
      })
      return result
    }

    /**
     * @description: 对象内百分比转小数格式/小数转百分比格式
     * @param {Boolean} isReFormat - 决定转换类型
     * @return {Object} 转换后的新对象
     */
    function typeRateFormat (obj, isReFormat) {
      let newObj = {}
      Object.keys(obj).forEach((x) => {
        const findObj = needEdit.find((j) => j.variable === x)
        if (x.indexOf('Error') < 0) {
          newObj[x] =
            findObj && findObj.type === 'rate'
              ? isReFormat
                ? String(NP.times(obj[x], 100))
                : NP.divide(obj[x], 100)
              : obj[x]
        }
      })
      return newObj
    }
    // 根据加价率计算
    function getPriceByTax (value, tax, precision = 2) {
      // 先四舍五入后 再toFixed 即可小数点后补0  也不会受toFixed银行家舍入法 的影响
      return NP.round(NP.times(+value, +tax + 1), precision).toFixed(precision)
    }

    function formValidate () {
      return formDisabled.value
        ? skuBatchRef.value.handleSubmit()
        : skuNormalRef.value.handleSubmit()
    }
    function getMathRound (value, precision) {
      return NP.round(value, precision)
    }
    return {
      needEdit,
      skuBatchRef,
      edit,
      skuNormalRef,
      formDisabled,
      getDefaultObj,
      typeRateFormat,
      getPriceByTax,
      formValidate,
      getMathRound,
    }
  },
}
</script>

<style lang="less" scoped>
.sku-setting-box {
  ::v-deep .sku-setting {
    padding-top: 20px;
  }
  ::v-deep .label {
    text-align: right;
    line-height: 40px;
  }
  ::v-deep .extra {
    padding-top: 4px;
    min-height: 22px;
    margin-top: -2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
